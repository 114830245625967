import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import Tabs, { TabsContent, TabsVariant } from '@components/utility/Tabs/Tabs';
import { useCallback, useMemo, useState } from 'react';
import Card from '@components/utility/Card/Card';
import './Menu.styles.scss';
import { generateAnchorId } from '@core/utils/string';
import { IconDoubleArrow } from '@assets/icons';
import Button from '@components/utility/Button/Button';
import { MenuResponse, MenuItem, MenuHeader } from '@core/api/useGlobalMenu';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { formatPrice } from '@core/utils/currency';

const transformMenuDataToTabs = (menuData: MenuResponse, countryCode: CountryCodesEnum): TabsContent[] => {
    const masterHeaders = menuData.flatMap((data) => data.headers?.filter((header) => header.type === 'MASTER') || []);
    const emptyMasterHeaderItems: MenuItem[] = [];

    if (!menuData[0]) return [];

    menuData.forEach((data) => {
        data.headers?.forEach((header) => {
            if (header.type === 'MASTER' && !header.value?.length) {
                header.headers?.[0].headers?.forEach((subHeader) => {
                    emptyMasterHeaderItems.push(...(subHeader.items || []));
                });
            }
        });
    });

    const tabs = masterHeaders
        .filter((masterHeader) => masterHeader.type === 'MASTER' && masterHeader.value)
        .map((masterHeader) => {
            const anchorId = generateAnchorId(masterHeader.value, 'menu-tab-');
            const items =
                masterHeader.headers?.flatMap(
                    (primaryHeader) => primaryHeader.headers?.flatMap((subHeader) => subHeader.items || []) || [],
                ) || [];

            return {
                label: <span data-testid={`heading${masterHeader.value}`}>{masterHeader.value}</span>,
                title: masterHeader.value,
                content: (
                    <div className="MenuTabs__section__items" id={anchorId}>
                        {items.map((item: MenuItem, itemIndex: number) => (
                            <div className="MenuTabs__section__item" key={`${item.name}-${itemIndex}`}>
                                <Card
                                    name={item.name}
                                    description={item.description}
                                    price={formatPrice(countryCode, item.price as number) ?? ''}
                                />
                            </div>
                        ))}
                    </div>
                ),
                onClick: () => {
                    const element = document.getElementById(anchorId);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                },
            };
        });

    if (emptyMasterHeaderItems.length > 0) {
        const othersAnchorId = generateAnchorId('others', 'menu-tab-');
        tabs.push({
            label: <span data-testid="headingOthers">Others</span>,
            title: 'Others',
            content: (
                <div className="MenuTabs__section__items" id={othersAnchorId}>
                    {emptyMasterHeaderItems.map((item: MenuItem, itemIndex: number) => (
                        <div className="MenuTabs__section__item" key={`${item.name}-${itemIndex}`}>
                            <Card
                                name={item.name}
                                description={item.description}
                                price={formatPrice(countryCode, item.price as number) ?? ''}
                            />
                        </div>
                    ))}
                </div>
            ),
            onClick: () => {
                const element = document.getElementById(othersAnchorId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            },
        });
    }

    return tabs;
};

const Menu = ({
    menuData,
    countryCode,
    operatorProfileId,
}: {
    menuData: MenuResponse;
    countryCode: CountryCodesEnum;
    operatorProfileId: string;
}) => {
    const [resetActiveTab, setResetActiveTab] = useState(false);

    const tabsContent = useMemo(
        () => (menuData ? transformMenuDataToTabs(menuData, countryCode) : []),
        [menuData, countryCode],
    );

    const scrollToFirstTab = useCallback(() => {
        const firstTab = document.getElementById(operatorProfileId);
        if (firstTab) {
            firstTab.scrollIntoView({ behavior: 'smooth' });
        }
        setResetActiveTab(true);
    }, [operatorProfileId]);

    const isAllMasterHeaderEmpty = useCallback((): boolean => {
        const checkHeaders = (headers: MenuHeader[]): boolean => {
            for (const header of headers) {
                if (header.type === 'MASTER' && header.value !== '') {
                    return false;
                }
                if (header.headers && !checkHeaders(header.headers)) {
                    return false;
                }
            }
            return true;
        };

        return menuData.every((data) => data.headers && checkHeaders(data.headers));
    }, [menuData]);

    const extractEmptyHeaderItems = useCallback((headers: MenuHeader[]): MenuItem[] => {
        let items: MenuItem[] = [];
        headers.forEach((header) => {
            if (header.items) {
                items = items.concat(header.items);
            }
            if (header.headers) {
                items = items.concat(extractEmptyHeaderItems(header.headers));
            }
        });
        return items;
    }, []);

    const emptyHeaderItems = useMemo(() => {
        return menuData?.[0]?.headers ? extractEmptyHeaderItems(menuData[0].headers) : [];
    }, [menuData, extractEmptyHeaderItems]);

    return (
        <>
            <Widget type={WidgetType.Section} className="MenuTabs">
                {!isAllMasterHeaderEmpty() ? (
                    tabsContent.length > 1 && (
                        <Tabs
                            tabsContent={tabsContent}
                            resetActiveTab={resetActiveTab}
                            variant={TabsVariant.HorizontalScroll}
                            hasSharedContent={true}
                        />
                    )
                ) : (
                    <div className="MenuTabs__section__items">
                        {emptyHeaderItems.map((item, itemIndex) => (
                            <div className="MenuTabs__section__item" key={`${item.name}-${itemIndex}`}>
                                <Card
                                    name={item.name}
                                    description={item.description}
                                    price={formatPrice(countryCode, item.price) ?? ''}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </Widget>
            {tabsContent.length > 0 && (
                <Button onClick={scrollToFirstTab} className="MenuTabs__back-to-top">
                    <IconDoubleArrow className="Collapse__double-arrow" />
                </Button>
            )}
        </>
    );
};

export default Menu;
